<template>
  <div>
    <!-- Recommend Course Modal For Counselor -->
    <b-modal id="counselor-reccomend-modal-id" ref="ref-counselor-reccomend-modal" centered title="Select Students"
      ok-title="Submit" hide-footer @hidden="resetSelectedStudents">
      <!-- @ok="recommendToStudents" -->
      <br />
      <b-button @click="recommendToStudents">RECCOMEND</b-button>
      <br />
      <br />
      {{ this.recommend_course ? this.recommend_course.course_name : "" }}
      <br />
      <v-select multiple :reduce="(student) => student.student_user_id" v-model="students_selected" label="unique_name"
        :options="assigned_students" :closeOnSelect="false">
        <template #option="data">
          <b-avatar :src="FILESURL + data.user_profile_image" size="16" class="align-middle mr-50" />
          <span> {{ data.unique_name }}</span>
        </template>
      </v-select>
    </b-modal>

    <!-- Apply To Course Modal For Agent -->
    <b-modal id="agent-apply-modal-id" ref="ref-agent-apply-modal" centered title="Select Student" ok-title="Apply"
      no-close-on-backdrop @hidden="clearModalDetails" @ok.prevent="applyCourseForAgent">
      <br />
      {{
        this.selected_apply_course ? this.selected_apply_course.course_name : ""
      }}
      <br />
      <br />
      <br />
      Intake month
      <v-select v-model="selected_intake_month" :options="selected_course_intakes" />
      <br />
      Intake Year
      <v-select v-model="selected_intake_year" :options="all_years" />
      <br />
      Select Student
      <v-select :reduce="(student) => student.student_user_id" label="unique_name" v-model="selected_student_user_id"
        :options="assigned_students" :closeOnSelect="true">
        <template #selected-option="data">
          <b-avatar :src="FILESURL + data.user_profile_image" size="16" class="align-middle mr-50" />
          <span> {{ data.unique_name }}</span>
        </template>
        <template #option="data">
          <b-avatar :src="FILESURL + data.user_profile_image" size="16" class="align-middle mr-50" />
          <span> {{ data.unique_name }}</span>
        </template>
      </v-select>
    </b-modal>

    <!-- Select intake month and year student modal -->
    <b-modal id="id-student-select-intake-modal" ref="ref-student-select-intake-modal" centered title="Select Intake"
      ok-title="Apply" no-close-on-backdrop @hidden="clearModalDetails" @ok.prevent="applyToCourse">
      <br />
      {{
        this.selected_apply_course ? this.selected_apply_course.course_name : ""
      }}
      <br />
      <br />
      Intake month
      <v-select v-model="selected_intake_month" :options="selected_course_intakes" />
      <br />
      Intake Year
      <v-select v-model="selected_intake_year" :options="all_years" />
      <br />
      Campus Locations
      <v-select v-model="selected_campus_location" :options="campus_locations" />
    </b-modal>

    <Header :university_details="university_details" />
    <section class="university-about mt-3">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="box-shadow px-2 py-2 mb-2" id="benefits">
              <div class="title">Institutional Benefits</div>
              <div class="detail-content" v-html="university_details.benefits"></div>
              <div class="cards-overflow">
                <div class="about-cards mt-5">
                  <div v-if="university_details.monthly_living_cost" class="box-shadow card-col py-4 px-1 mb-2">
                    <div class="circle">
                      <b-avatar size="80" variant="light-success">
                        <feather-icon size="30" icon="HomeIcon" />
                      </b-avatar>
                    </div>
                    <div class="pt-5">
                      <div class="light-grey-text">Monthly Living Cost</div>
                      <div class="font-weight-bold pt-1">
                        {{
                          university_details.country_currency_code +
                          " " +
                          university_details.monthly_living_cost
                        }}
                      </div>
                    </div>
                  </div>
                  <div v-if="university_details.fund_for_visa" class="box-shadow card-col py-4 px-1 mb-2">
                    <div class="circle">
                      <b-avatar size="80" variant="light-danger">
                        <feather-icon size="30" icon="HomeIcon" />
                      </b-avatar>
                    </div>
                    <div class="pt-5">
                      <div class="light-grey-text">
                        Funds Requirement for Visa
                      </div>
                      <div class="font-weight-bold pt-1">
                        {{
                          university_details.country_currency_code +
                          " " +
                          university_details.fund_for_visa
                        }}
                      </div>
                    </div>
                  </div>
                  <div v-if="university_details.scholarship_policy" class="box-shadow card-col py-4 px-1 mb-2">
                    <div class="circle">
                      <b-avatar size="80" variant="light-danger">
                        <feather-icon size="30" icon="HomeIcon" />
                      </b-avatar>
                    </div>
                    <div class="pt-5">
                      <div class="light-grey-text">Scholarship Policy</div>
                      <div class="font-weight-bold pt-1" v-html="university_details.scholarship_policy"></div>
                    </div>
                  </div>
                  <div v-if="university_details.ptw_details" class="box-shadow card-col py-4 px-1 mb-2">
                    <div class="circle">
                      <b-avatar size="80" variant="light-warning">
                        <feather-icon size="30" icon="HomeIcon" />
                      </b-avatar>
                    </div>
                    <div class="pt-5">
                      <div class="light-grey-text">Part Time</div>
                      <div class="font-weight-bold pt-1" v-html="university_details.ptw_details"></div>
                    </div>
                  </div>
                  <div v-if="university_details.monthly_living_cost" class="box-shadow card-col py-4 px-1 mb-2">
                    <div class="circle">
                      <b-avatar size="80" variant="light-warning">
                        <feather-icon size="30" icon="HomeIcon" />
                      </b-avatar>
                    </div>
                    <div class="pt-5">
                      <div class="light-grey-text">Monthly Living Expenses</div>
                      <div class="font-weight-bold pt-1" v-html="university_details.monthly_living_cost"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="box-shadow px-2 py-2 mb-2" v-if="university_details.languages_required">
              <div class="title">Languages Required</div>

              <div class="font-weight-bold pt-1">
                <div v-html="university_details.languages_required" />
              </div>
            </div>
            <div class="box-shadow px-2 py-2 mb-2" id="ranking">
              <div class="title">Rankings</div>
              <div class="ranking__grid">
                <div v-if="university_details.qs_ranking">
                  <div class="sm-title">QS Ranking</div>
                  <div class="ranking-sub__grid">
                    <div class="d-flex align-items-end">
                      <div class="ranking__icon">
                        <b-avatar size="48" variant="light-success">
                          <feather-icon size="24" icon="HomeIcon" />
                        </b-avatar>
                      </div>
                      <div class="pl-2">
                        <!-- <div
                          class="ranking__num"
                          v-html="
                            getRankingArr(university_details.qs_ranking)[0]
                          "
                        ></div> -->
                      </div>
                    </div>
                    <div class="d-flex flex-column justify-content-between">
                      <div class="ranking__sm-text">World</div>
                      <div class="ranking__sm-text">National</div>
                    </div>
                  </div>
                </div>

                <div v-if="university_details.world_times_ranking">
                  <div class="sm-title">QS Ranking</div>
                  <div class="ranking-sub__grid">
                    <div class="d-flex align-items-end">
                      <div class="ranking__icon">
                        <b-avatar size="48" variant="light-success">
                          <feather-icon size="24" icon="HomeIcon" />
                        </b-avatar>
                      </div>
                      <div class="pl-2">
                        <div class="ranking__num" v-html="getRankingArr(
                          university_details.world_times_ranking
                        )[0]
                          "></div>
                      </div>
                    </div>
                    <div class="d-flex flex-column justify-content-between">
                      <div class="ranking__sm-text">World</div>
                      <div class="ranking__sm-text">National</div>
                    </div>
                  </div>
                </div>

                <div v-if="university_details.national_guardian_ranking">
                  <div class="sm-title">US World News Report</div>
                  <div class="ranking-sub__grid">
                    <div class="d-flex align-items-end">
                      <div class="ranking__icon">
                        <b-avatar size="48" variant="light-success">
                          <feather-icon size="24" icon="HomeIcon" />
                        </b-avatar>
                      </div>
                      <div class="pl-2">
                        <div class="ranking__num" v-html="getRankingArr(
                          university_details.national_guardian_ranking
                        )[0]
                          "></div>
                      </div>
                    </div>
                    <div class="d-flex flex-column justify-content-between">
                      <div class="ranking__sm-text">World</div>
                      <div class="ranking__sm-text">National</div>
                    </div>
                  </div>
                </div>

                <div v-if="university_details.us_world_news_report">
                  <div class="sm-title">US World News Report</div>
                  <div class="ranking-sub__grid">
                    <div class="d-flex align-items-end">
                      <div class="ranking__icon">
                        <b-avatar size="48" variant="light-success">
                          <feather-icon size="24" icon="HomeIcon" />
                        </b-avatar>
                      </div>
                      <div class="pl-2">
                        <div class="ranking__num" v-html="getRankingArr(
                          university_details.us_world_news_report
                        )[0]
                          "></div>
                      </div>
                    </div>
                    <div class="d-flex flex-column justify-content-between">
                      <div class="ranking__sm-text">World</div>
                      <div class="ranking__sm-text">National</div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="university_details.subject_ranking">
                <div class="title">Subject Rankings</div>
                <!-- <div>{{ university_details.subject_ranking }}</div> -->
                <!-- <div>{{ subjectRanking(university_details.subject_ranking) }}</div> -->
                <div v-html="university_details.subject_ranking" />
                <!-- <ul>
                    <span
                      v-for="(item, index) in subjectRanking(
                        university_details.subject_ranking
                      )"
                      :key="index"
                    >
                      <li v-if="item.length > 0">
                        {{ item }}
                      </li>
                      <br v-else />
                    </span>
                  </ul> -->
                <!-- </div> -->
              </div>
              <br />
              <div v-if="university_details.other_details">
                <div class="sm-title">Other Details</div>
                <div v-html="university_details.other_details" />
              </div>
            </div>
            <div v-if="user_type !== 'student'" class="box-shadow px-2 py-2 mb-2" id="ranking">
              <div class="title">Updates</div>
              <div class="ranking__grid" v-for="(info, index) in uniInfo" :key="'a' + index">
                <!-- <p>{{ info }}</p> -->

                <div v-html="info.mui_info" />
              </div>
            </div>
            <div v-if="university_details.courses" class="box-shadow px-2 py-2 mb-2" id="courses">
              <div class="title">Courses</div>
              <UniversityCourseDetail :courses="university_details.courses"
                @reload-details="getUniversityDetailsWithCourses" @apply-course="showIntakeModal"
                @recommend-course="showRecommendModal" @apply-course-for-student="showApplyModalForAgent" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import Header from "./Navigation";
import CourseProvider from "../Search/helper/CourseProvider.vue";
import UniversityCourseDetail from "../Search/helper/UniversityCourseDetail";
import StudentServices from "@/apiServices/StudentServices";
import { FILESURL } from "@/config";
import { all_months, getAllYears } from "@/assets/json_data/data";
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BButton,
  BBadge,
} from "bootstrap-vue";
import store from "@/store";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import AgentServices from "@/apiServices/AgentServices";
import CommonServices from "@/apiServices/CommonServices";
import helpers from "@/libs/helpers";

export default {
  components: {
    CourseProvider,
    UniversityCourseDetail,
    Header,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BButton,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      FILESURL,

      university_id: null,
      university_details: {},
      campus_locations: [],

      // Counselor Reccomendation
      students_selected: [],
      recommend_course: null,

      // Agent Apply
      selected_student_user_id: null,

      selected_intake_month: null,
      selected_intake_year: null,
      selected_apply_course: null,
      selected_course_intakes: [],
      selected_campus_location: null,

      all_months,
      uniInfo: [],
      usp: {},
      sur: {},
    };
  },
  watch: {
    "$store.state.user.user_details": function (val) {
      if (val && val.user_type !== "student") {
        this.getUniversityExtraInfo();
      }
    },
  },
  computed: {
    all_years() {
      return getAllYears();
    },
    assigned_students() {
      return store.getters["user/getAssignedStudents"];
    },
    user_type() {
      if (store.getters["user/getUserDetails"].user_type) {
        return store.getters["user/getUserDetails"].user_type;
      }
    },
  },
  methods: {
    showDropdown(value) {
      this.displayDropdown.splice(value, 1, !this.displayDropdown[value]);
    },
    getRankingArr(str) {
      let new_str = str;
      new_str = new_str.replaceAll('"', "");
      new_str = new_str.replaceAll("<br />", " ");
      new_str = new_str.replace("World : ", "");
      new_str = new_str.replace("National : ", "");
      new_str = new_str.split(" ");
      return new_str;
    },
    async getUniversityDetailsWithCourses() {
      if (this.university_id) {
        const university_id = this.university_id;
        try {
          const user_details = store.getters["user/getUserDetails"];
          let response = await CommonServices.universityDetailsWithCourses({
            university_id,
          });

          if (response.data.status) {
            this.university_details = response.datadata;
            console.log(response, "jawab de>>>>>")
          } else {
            console.log("Status False ", response.data.data);
          }
        } catch (error) {
          console.log("Error in getUniversityDetailsWithCourses ", error);
        }
      }
    },
    async getUniversityExtraInfo() {
      const university_id = this.university_id;

      try {
        let response = await CommonServices.getSingleUniversityExtraInfo({
          university_id,
        });
        console.log(response.data.data);
        if (response.data.status) {
          this.uniInfo = response.data;
        }
      } catch (error) {
        console.error("uniinfo :", error);
      }
    },
    clearModalDetails() {
      this.selected_student_user_id = null;
      this.selected_apply_course = null;
      this.selected_intake_month = null;
      this.selected_intake_year = null;
    },
    showApplyModalForAgent(e) {
      this.selected_apply_course = e;
      this.selected_course_intakes = e.intake ? e.intake.split("/") : [];
      this.$refs["ref-agent-apply-modal"].show();
    },
    async applyCourseForAgent() {
      try {
        const intake_month = this.selected_intake_month;
        const intake_year = this.selected_intake_year;
        if (!intake_month || !intake_year || !this.selected_student_user_id) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Please Select Intake and student",
              icon: "EditIcon",
              variant: "failure",
            },
          });
          return;
        }
        const response = await AgentServices.applyForStudent({
          student_user_id: this.selected_student_user_id,
          course_id: this.selected_apply_course.course_id,
          intake_month,
          intake_year,
        });
        if (response.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Successfully Applied",
              icon: "CheckIcon",
              variant: "success",
            },
          });
          this.$refs["ref-agent-apply-modal"].hide();
        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Not Applied",
              icon: "XIcon",
              variant: "failure",
            },
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Error in applying",
            icon: "X",
            variant: "failure",
          },
        });
        console.log("Error in applying to course for agent", err);
      }
    },
    resetSelectedStudents() {
      this.students_selected = [];
      this.recommend_course = null;
      this.$refs["ref-counselor-reccomend-modal"].hide();
    },
    showRecommendModal(e) {
      this.recommend_course = e;
      this.$refs["ref-counselor-reccomend-modal"].show();
    },
    async recommendToStudents(e) {
      try {
        const response = await CommonServices.reccomendCourseToStudents({
          course_id: this.recommend_course.course_id,
          student_user_ids: this.students_selected,
        });
        if (response.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Success",
              icon: "Check",
              variant: "success",
            },
          });
        }
      } catch (err) {
        console.log("Error in recommending course to student ", err);
      }
      this.resetSelectedStudents();
    },
    showIntakeModal(course) {
      this.selected_apply_course = course;
      this.campus_locations = helpers.formatIntakeForApplyModal(
        course.multiple_campus_locations
      );
      this.selected_course_intakes = course.intake
        ? course.intake.split("/")
        : [];
      this.$refs["ref-student-select-intake-modal"].show();
    },
    applyToCourse() {
      const course_id = this.selected_apply_course.course_id;
      const intake_month = this.selected_intake_month;
      const intake_year = this.selected_intake_year;
      const campus_location = this.selected_campus_location;

      if (!intake_month || !intake_year) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Please Select Intake",
            icon: "EditIcon",
            variant: "failure",
          },
        });
        return;
      }
      StudentServices.ApplyCourse({
        course_id,
        intake_month,
        intake_year,
        campus_location,
      })
        .then((response) => {
          if (response.data.status) {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: "Course Applied Successfully",
                icon: "CheckSquareIcon",
                variant: "success",
              },
            });
            this.$refs["ref-student-select-intake-modal"].hide();
            this.getUniversityDetailsWithCourses();
          } else {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: response.data.message || "Cannot Apply to course",
                icon: "X",
                variant: "failure",
              },
            });
            console.log("Status false");
          }
        })
        .catch((err) => {
          console.log("Error Applying to course ", err);
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Error Applying to course",
              icon: "XIcon",
              variant: "failure",
            },
          });
        });
    },
  },
  beforeMount() {
    const { university_id } = this.$route.params;
    if (!isNaN(university_id)) {
      this.university_id = university_id;
      this.getUniversityDetailsWithCourses();
      // this.getUniversityExtraInfo();
    } else {
      console.log("University is is not a number ", university_id);
    }
  },
};
</script>
<style>
.my-list b-list-group-item {
  padding-bottom: 10px;
}

.university-about .box-shadow,
.university-header .box-shadow {
  background-color: white !important;
  border-radius: .428em !important;
}
</style>
